<template>
  <div
    class="home"
    v-loading="loading" :style="cssVars">
    <!--    课程菜单-->
    <el-row class="flex_row">
      <el-col :span="24">
        <div class="video">
          <video-player

            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="playsinline"
            :options="playerOptions"
            @play="onPlayerPlay($event)"
            @pause="onPlayerPause($event)"
            @timeupdate="onPlayerTimeupdate($event)"
            @ended="onPlayerEnded($event)"
          />
          <div
            class="shade"
            @click="videoPlay"></div>
        </div>
        <div class="info">
          <div class="title">
            <div>{{ courseInfo.name }}</div>
            <div class="control">
              <span
                  v-if="islike"
                class="pointer"
                @click="handleLike">
                <img
                  :src="isGood ? good_1 : good"
                  :class="isGood ? 'my-element' : ''" />
                点赞
              </span>
              <span
                  v-if="islogin"
                class="pointer"
                @click="handleCollage">
                <img
                  :src="isCollage ? college_1 : college"
                  :class="isCollage ? 'my-element' : ''" />
                收藏
              </span>
              <el-popover
                  v-if="islogin"
                  class="pointer"
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="click">
                <canvas id="canvas" ref="canvas"></canvas>
                <el-button slot="reference">分享</el-button>
              </el-popover>
              <span id="share" data-disabled="wechat , facebook, twitter, google"></span>
            </div>
          </div>
          <div class="box">
            <div class="list">
              <i class="el-icon-user"></i>
              主讲人:{{ courseInfo.author }}
            </div>

            <div class="list">
              <i class="el-icon-view"></i>
              浏览量:{{ courseInfo.browseNumber }}人
            </div>
            <div class="list">
              <i class="el-icon-thumb"></i>
              点赞量:{{ courseInfo.linkNum }}人
            </div>
            <div class="list">
              <i class="el-icon-video-play"></i>
              实时观看人数:{{ courseInfo.onlineCount }}人
            </div>
          </div>
        </div>
        <div class="introduce">
          <el-tabs v-model="activeName">
            <el-tab-pane
              label="课程介绍"
              name="1">
              <div
                v-html="courseInfo.introduce"
                style="font-size: 15px"></div>
            </el-tab-pane>
            <el-tab-pane
              label="评论"
              name="2">
              <div class="leave">
                <div class="flex_row" v-if="islogin">
                  <div class="photo">
                    <img src="../../assets/image/avatar/maleStudent.png" />
                  </div>
                  <div class="pl">
                    <el-input
                      type="textarea"
                      v-model="message"></el-input>
                    <el-button
                      style="width: 120px; margin-top: 10px"
                      @click="sendMsg">
                      发表
                    </el-button>
                  </div>
                </div>
                <div
                  class="msgList"
                  v-for="item in msgList"
                  :key="item.id">
                  <div class="photo">
                    <img :src="item.userAvatar ? item.userAvatar : header" />
                    {{ item.userName }}
                  </div>
                  <div class="msg">{{ item.reviewContent }}</div>
                </div>
                <div
                  class="more"
                  v-show="total > parmas.size"
                  @click="getMoreList">
                  加载更多
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </el-col>
    </el-row>
    <!--内容-->
    <el-row class="flex_row">
      <Title
        title="相关推荐"
        :showMore="true"
        @getMore="getMore">
        <div class="course-list">
          <Course
            :info="item"
            v-for="item in hotResourcesList"
            :key="item.id"></Course>
        </div>
      </Title>
    </el-row>
  </div>
</template>

<script>
import { sessionGet } from '@/utils/local';
import Title from '@/components/8.30/Title';
import QRCode from "qrcode";
import {
  getDetail,
  getHotResources,
  addResourcesLike,
  addResourcesCollect,
  getResourcesClassifyPage,
  review,
  reviewPage,
  getDataPage,
  addViewer,
} from '@/api/8.30';
import Course from '@/components/8.30/Course';
import {sessionClear} from "../../utils/local";
export default {
  name: 'index',
  components: {
    Title,
    Course,
  },
  data: () => {
    return {
      header: require('@/assets/image/avatar/maleStudent.png'),
      parmas: {
        current: 1,
        size: 10,
      },
      zIndex:9999,
      loading: false,
      msgList: [],
      total: '',
      courseInfo: '',
      viewerId: null,
      likeId: null,
      timeupdateTimes: 0,
      lastSeconds:0,
      hotResourcesList: '',
      islogin: false,
      islike: false,
      classify: '',
      activeName: '1',
      good: require('@/assets/image/likes1.png'),
      good_1: require('@/assets/image/likes2.png'),
      college: require('@/assets/image/collection.png'),
      college_1: require('@/assets/image/collection2.png'),
      isGood: false,
      isGoodNums: 0,
      isCollage: false,
      message: '',
      playsinline: true,
      // 视频播放
      playerOptions: {
       height:"500px",
        //playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        controls: true, //是否显示控制条
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
       // aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4',
            src: '', // url地址
          },
        ],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {

        },
      },
    };
  },
  async mounted() {
    window.addEventListener('beforeunload', function (e) {
      sessionClear('routeparams');
    });
    this._islogin();
    this._isMobile();
    this.loading = true;
    //查询详情
    const { data } = await getDetail({
      id: this.$route.query.courseId,
    });
    this.courseInfo = data;
    this.isGood = data.isLike;
    this.islike = this.isGood;
    this.isCollage = data.isCollection;

    const pDate = new Date(data.playDate);
    let duration = data.duration;
    // console.log('pDate::'+pDate);
    // console.log('duration::'+duration);
    const oDate = new Date(pDate.getTime() + duration * 1000);
    const now = new Date();
    // console.log('oDate::'+oDate);

    //判断是否往期课程，如果往期显示更多控制功能
    if(data.isNew && now.getTime() < oDate.getTime()){
      this.playerOptions.controlBar= {
          playToggle:false,
          progressControl:false,
           timeDivider: false, // 当前时间和持续时间的分隔符
           durationDisplay: false, // 显示持续时间
           remainingTimeDisplay: false, // 是否显示剩余时间功能
           fullscreenToggle: true ,// 是否显示全屏按钮
          volumeControl: true,
        };
    }else{
      this.playerOptions.controlBar= {

        };
    }
    this.playerOptions.sources[0].src = data.resourcesUrl+"?rnd="+new Date();
    //评论详情
    this.getMsg();
    //获取相关资源
    const { data: correlation } = await getDataPage({
      contentsId: this.courseInfo.contentsId,
      current: 1,
      size: 4,
    });
    this.hotResourcesList = correlation.records;
    this.loading = false;
    this.useqrcode();
  },
  computed:{
     cssVars() {
        return {
          '--zIndex': this.zIndex,
        };
      }
  },
  methods: {
    _islogin(){
      let uInfo = sessionGet('userInfo');
      if(uInfo){
        this.islogin = true;
        //console.error('已登录');
      }else{
        this.islogin = false;
        //console.error('未登录');
      }
    },
    useqrcode(){
      if(this.islogin){
        // 调用函数去生成二维码，参数依次为：二维码的容器、要生成的内容、回调函数
        console.log('window.location.href::'+window.location.href);
        QRCode.toCanvas(this.$refs.canvas,window.location.href,function(error) {
          if (error) {
            console.error('error::'+error);
          } else {
            console.log("success!");
          }
        });
      }
    },
    _isMobile(){
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      console.log("flag===="+flag)
      if(flag != null){
        const href = window.location.href;
        window.location.href = href.replace("#","h5/#/pages/index");
        //console.log("window.location.href===="+href.replace("#","h5/#/pages/index"))
      }
    },
    videoPlay(e) {
      if (this.courseInfo.isPlay) {
        this.$refs.videoPlayer.player.play();
        //取消遮罩，用户可操作控制面板
        this.zIndex=-1;
        this.islike = true;
      } else {
        this.zIndex=9999;
        this.$message.warning('开播时间为周三晚20：30，敬请期待！');
      }
    },
    //获取评论
    async getMsg() {
      const { data: msg } = await reviewPage({
        resourceId: this.$route.query.courseId,
        ...this.parmas,
      });
      this.msgList = msg.records;
      this.total = msg.total;
    },

    //点赞
    handleLike() {
      // if(this.isGood && !this.islogin){
      //   return;
      // }
      this.isGood = !this.isGood;
      addResourcesLike({
        id: this.$route.query.courseId,
        type: this.isGood ? '1' : '0',
        likeId: this.likeId == false || this.likeId == true ? null : this.likeId,
      }).then((res) => {
        if (res.code == 200) {
          this.likeId = res.data;
          this.$message.success('操作成功');
        }
      });
    },
    //收藏
    handleCollage() {
      this.isCollage = !this.isCollage;
      addResourcesCollect({
        id: this.$route.query.courseId,
        type: this.isCollage ? '1' : '0',
      }).then((res) => {
        if (res.code == 200) {
          this.$message.success('操作成功');
        }
      });
    },
    getMore() {
      this.$router.push('/More');
    },
    sendMsg() {
      if (this.message) {
        review({ content: this.message, resourcesId: this.courseInfo.id }).then(
          (res) => {
            if (res.code == 200) {
              this.$message.success('评论成功');
              this.message = '';
            }
          }
        );
      } else {
        this.$message.warning('请输入内容!');
      }
    },
    getMoreList() {
      if (this.total > this.parmas.size) {
        this.parmas.size += 10;
        this.getMsg();
      } else {
        this.$message.success('已经是最后一条评论了');
      }
    },

    //视频事件
    getDate(){
      const now = new Date(Date.now());
      const year = now.getFullYear(); // 年份，例如：2021
      const month = now.getMonth() + 1; // 月份，注意+1，例如：8
      const date = now.getDate(); // 日，例如：13
      const hour = now.getHours(); // 小时，例如：11
      const minute = now.getMinutes(); // 分钟，例如：30
      const second = now.getSeconds(); // 秒，例如：59
      const mSecond = now.getMilliseconds();
      const formattedTime = `${year}-${month}-${date} ${hour}:${minute}:${second}.${mSecond}`;
      return formattedTime;
    },
    getSecond(){
      const now = new Date(Date.now());
      return now.getSeconds();
    },
    onPlayerPlay(event){
      this.lastSeconds = this.getSecond();
      addViewer({ id: this.courseInfo.id ,  viewerId:this.viewerId, seconds:this.timeupdateTimes}).then(
          (res) => {
            if (res.code == 200) {
              this.viewerId = res.data;
              this.timeupdateTimes = 0;
              // console.log(res);
            }
          }
      );
      // console.log('播放开始:'+this.getDate());
    },
    onPlayerPause(event){
      // console.log('播放暂停:'+this.getDate());
    },
    onPlayerTimeupdate(event){//一秒触发一次
      var thislastSeconds = this.getSecond();
      if(thislastSeconds != this.lastSeconds){
        this.timeupdateTimes ++;
        this.lastSeconds = thislastSeconds;
      }

      if(this.timeupdateTimes == 10){//10秒记录一次时间
        addViewer({ id: this.courseInfo.id,  viewerId:this.viewerId, seconds:this.timeupdateTimes}).then(
            (res) => {
            }
        );
        this.timeupdateTimes = 0;
      }
      // console.log('定时触发:'+this.getDate() + ':::' + this.timeupdateTimes);
    },
    onPlayerEnded(event){
      if(this.timeupdateTimes != 0){
        addViewer({ id: this.courseInfo.id,  viewerId:this.viewerId, seconds:this.timeupdateTimes}).then(
            (res) => {
            }
        );
        this.viewerId = null;
        this.timeupdateTimes = 0;
      }
      // console.log('播放结束:'+this.getDate());
    },
  },
};
</script>
<style scoped lang="scss">

::v-deep .vjs-tech {
  pointer-events: none;
}
/* 动画效果*/
.my-element {
  // display: inline-block;
  animation-name: bounceIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 0.8s; /* don't forget to set a duration! */
}
// .zoomIn {
// animation-name: zoomIn;
// animation-duration: 1s;
// }
@keyframes bounceIn {
  0% {
    opacity: 0;

    transform: scale(0.3);
  }
  10% {
    opacity: 0.2;

    transform: scale(0.6);
  }
  20% {
    opacity: 0.5;

    transform: scale(1);
  }
  30% {
    opacity: 0.7;

    transform: scale(1.3);
  }
  40% {
    opacity: 0.8;

    transform: scale(1.45);
  }
  50% {
    opacity: 1;

    transform: scale(1.65);
  }
  60% {
    transform: scale(1.45);
  }
  70% {
    transform: scale(1.35);
  }
  80% {
    transform: scale(1.2);
  }
  90% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
::v-deep .el-tabs__nav-wrap::after {
  height: 0;
}
::v-deep .el-tabs__item:hover {
  color: #0f4444;
}
::v-deep .el-tabs__item.is-active {
  color: #0f4444;
}
::v-deep .el-tabs__item {
  font-size: 18px;
  font-weight: 700;
  color: #999;
}
::v-deep .el-tabs__active-bar {
  background-color: #0f4444;
  width: 30px !important;
  height: 4px;
}
::v-deep .video-js.vjs-playing .vjs-tech {
  pointer-events: none;
}
.video {
  position: relative;
  .shade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    z-index: var(--zIndex);
    cursor: pointer;
  }
}
.home {
  width: 120rem;
  margin: 1rem auto;
  position: relative;
}
.video {
  height: 500px;

  // overflow: hidden;
}
.info {
  width: 100%;
  background-color: #fff;
  padding: 20px;
  .title {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    .control {
      font-size: 15px;
      color: #333;
      font-weight: 500;
      display: flex;
      span {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        margin-left: 20px;
      }
    }
  }
  .box {
    opacity: 0.6;
    display: flex;
    margin-top: 12px;
    .list {
      margin-right: 30px;
    }
  }
}
.introduce {
  margin-top: 8px;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  .leave {
    .photo {
      img {
        border-radius: 50%;
        height: 50px;
        width: 50px;
        margin-right: 15px;
      }
    }
    .pl {
      width: 100%;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    .more {
      text-align: center;
      font-size: 14px;
      opacity: 0.5;
      cursor: pointer;
    }
    .msgList {
      width: 100%;
      margin-bottom: 15px;
      margin-left: 8px;
      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .photo {
        display: flex;
        align-items: center;
        font-size: 15px;
      }

      .msg {
        opacity: 0.6;
        color: #000000;
        font-size: 14px;
        padding-left: 35px;
      }
    }
  }
}
.course-list {
  display: grid;
  grid-template-columns: repeat(4, 290px);
  justify-content: space-between;
  grid-auto-rows: 350px;
}
</style>
